<template>
  <section class="gardenManagementPage">
    <SearchForm class="dis-flex flex-x-start flex-y-start">
      <el-form
          ref="ref_searchForm"
          :inline="true"
          label-width="80px"
        >
        <el-form-item >
            <div style="width: 220px">
              <el-select
                v-model="unitType"
                placeholder="订单状态"
                @change="changeUnit"
                class="t-c"
              >
                <el-option
                  class="t-c"
                  v-for="(item, index) in unitList"
                  :key="index"
                  :label="item.name"
                  :value="item.name"
                />
              </el-select>
            </div>
          </el-form-item>
          <el-form-item>
            <el-date-picker
              v-model="dayTime"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              format="yyyy-MM-dd"
              data-format="yyyy-MM-dd"
              @change="selectTime"
            >
            </el-date-picker>
            <el-radio-group v-model="radio1" @change="changeLabel">
              <el-radio-button label="近7天"></el-radio-button>
              <el-radio-button label="近30天"></el-radio-button>
            </el-radio-group>

          </el-form-item>
          <el-form-item >
            <el-input
            style="width: 240px"
              v-model.trim="searchForm.orderNo"
              placeholder="订单号"
            />
          </el-form-item>
          <el-form-item>
            <el-input v-model.trim="searchForm.name" placeholder="收货人" />
          </el-form-item>
          
          <el-form-item class="m-left25">
            <el-button type="primary" @click="paramcshopmallIntegralPage">筛选</el-button>
          </el-form-item>
          <div>
           
          <el-form-item class="m-left25">
            <!-- <el-button type="primary" @click="clearData">批量导出</el-button> -->
            <download-excel class="export-excel-wrapper" :data="jsonData" :name="currentTime+'订单管理.xlsx'">
				    	<el-button @click="down" type="primary">导出订单</el-button>
				</download-excel>
          </el-form-item>
          </div>
  
        </el-form>
     
    </SearchForm>

    <TableContainer title="">
      <el-table
        :data="tableData"
        stripe
        style="width: 100%"
        border
        :header-cell-style="tabHeader"
        :cell-style="{ textAlign: 'center' }"
      >
       
        <el-table-column prop="addDay" label="订单信息" min-width="120" >
          <template slot-scope="scope">
            <div class="t-l">
            <p>ID:{{ scope.row.id }}<span style="margin-left:10px">商户名称:{{ scope.row.mchName }}</span></p> 
            <p>下单时间:{{ scope.row.addDay }}</p>
            <p>订单号:{{ scope.row.orderNo }}</p>
            <p>用户:{{ scope.row.nickname }}</p>
            <p style="color:red" v-show="scope.row.remark!==''">订单备注:{{ scope.row.remark }}</p>

            
          
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="收货人信息" min-width="100" >
          <template slot-scope="scope">
            <div class="t-l">
            <p>收货人:{{ scope.row.name }}</p>
            <p>电话:{{ scope.row.mobile }}</p>
            <p>地址:{{ scope.row.address }}</p>    
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="goodsName" label="商品信息" min-width="150">
          <template slot-scope="scope">
            <div class="dis-flex flex-x-start flex-y-center">
              <el-popover
              placement="left"
              width="400"
              trigger="click">
               <img  :src="scope.row.pic"  width="400px" height="400px" >
            <img  slot="reference" :src="scope.row.pic" width="60px" height="60px" >
          </el-popover>
              <div class="t-l m-left25">
                <p>{{scope.row.goodsName}}</p>
                <p>规格:默认 <span>数量： {{scope.row.num}}</span></p>
                <p>小计：{{scope.row.payPrice}}元</p>    
            </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="totalPrice" label="实际付款" min-width="100" >
          <template slot-scope="scope">
            <div>
              <p>抵扣积分:{{scope.row.integral}}积分</p>
              <p>总金额：{{scope.row.totalPrice}}元（含运费{{scope.row.expressPrice}}元）</p>
              
    
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="payName" label="订单状态" min-width="140" >
          <template slot-scope="scope">
            <div class="t-l">
              <div class="dis-flex flex-x-between flex-y-center">
              <p class="width50">{{scope.row.typeName}}{{scope.row.payName}}</p>
              <p  class="width50">发货方式：{{scope.row.offlineName}}</p>  
            </div>
            <div class="dis-flex flex-x-between flex-y-center">
              <p  class="width50">发货状态：<span style="border-radius: 4px;color:#fff;padding:4px;font-size:10px;"  :style="{backgroundColor:(scope.row.isSend!==0?'#5cb85c':'#636c72')}">{{scope.row.sendName}}</span></p>    
              <p  class="width50" v-show="scope.row.isSend!==0&&scope.row.expressNo!==''">快递公司：{{scope.row.express}}</p>     
            </div>
            <p v-show="scope.row.isSend!==0&&scope.row.expressNo!==''" >快递单号：<a :href="'https://www.baidu.com/s?wd='+scope.row.expressNo" target="_blank" style="color:#0275d8">{{scope.row.expressNo}}</a></p>    
            <p v-show="scope.row.isSend!==0&&scope.row.words!==null" >商家备注：{{scope.row.words}}</p>    
            <p v-show='scope.row.isSend!==0'>收货状态：<span style="border-radius: 4px;color:#fff;padding:4px;font-size:10px"  :style="{backgroundColor:(scope.row.isConfirm!==0?'#5cb85c':'#636c72')}">{{scope.row.confirmName}}</span></p>    
           
            </div>
          </template>
        </el-table-column>
 
        
        <el-table-column width="300" label="操作">
          <template slot-scope="scope">
            <!-- <el-button type="text" class="textOrange" @click="searchClick(scope.row)"
              >详情</el-button
            > -->
            <el-button type="text" class="textOrange" @click="sendClick(scope.row,1)" v-show="scope.row.isSend==0&&scope.row.applyDelete==0">发货</el-button>
            <el-button type="text" class="textOrange" @click="editorClick(scope.row)" v-show="scope.row.isSend==1&&scope.row.isConfirm==0">修改快递单号</el-button>
            <el-button type="text" class="textOrange" @click="approveClick(scope.row,1)" v-show="scope.row.isSend==0&&scope.row.applyDelete==1"
              >同意请求</el-button>
              <el-button type="text" class="textOrange" @click="approveClick(scope.row,0)" v-show="scope.row.isSend==0&&scope.row.applyDelete==1"
              >拒绝请求</el-button>
          </template>
        </el-table-column>
      </el-table>
      <GPagination
        v-show="total > 0"
        ref="ref_GPagination"
        :total="total"
        :page.sync="searchForm.page"
        :size.sync="searchForm.size"
        @pagination="paramcshopmallIntegralPage"
        :page-sizes="pageSizes"
      />
    </TableContainer>

    
   


    <!-- 修改快递单号 s -->
        <GDialog
      :dialog.sync="approveDialog"
      @btnSure="approvesubmitThemeForm()"
      @btnClose="resetThemeForm()"
      width="30%"
    >
      <el-form ref="ref_themeForm" label-width="100px" @submit.native.prevent>
     
      <el-form-item label="物流选择" label-width="100px">
          <el-radio-group v-model="logistics" @change="handleRadioChange">
          <el-radio label="0">快递</el-radio>
          <!-- <el-radio label="1">无需物流</el-radio> -->
        </el-radio-group>
      </el-form-item>
      <el-form-item label="快递公司" label-width="100px" v-show="this.logistics=='0'">
          <div style="width: 220px">
            <el-select
                v-model="updateParmas.express"
                placeholder="快递公司"
                @change="logisticsUnit"
                class="t-c"
              >
                <el-option
                  class="t-c"
                  v-for="(item, index) in logisticsList"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
          </div>
        </el-form-item>
        <el-form-item label="快递单号" label-width="100px"  v-show="this.logistics=='0'">
          <div style="width: 220px">
            <el-input
              v-model.trim="updateParmas.expressNo"
              placeholder="请输入快递单号"
              type="text"
            />
          </div>
        </el-form-item>
        <el-form-item label="商家留言(选填)" label-width="110px"  >
          <div >
            <el-input  v-model.trim="updateParmas.words" type="textarea" maxlength="100" placeholder="请输入" />
          </div>
        </el-form-item>
  
      </el-form>
    </GDialog>
    <!-- 修改快递单号e -->
    <!-- 1111 -->
  </section>
  </section>
</template>

<script>
import { parseTime } from "@utils/filters";
import {
  querycshopmallIntegralOrder,
  paramcshopmallIntegralPage,
  updatecshopmallIntegralOrder,
  updatecshopmallKocActivity,
  updatecshopmallKocNotes,
  delcshopmallKocNotes,
  deljfProduct,
  addjfProduct,
  productintegralSum,
  checkshopmallIntegralOrder
} from "@api/bx/api_contentManagement";
import uploadPics from "@/components/Upload/uploadPics.vue";
import uploadPicss from "@/components/Upload/uploadPicss.vue";

export default {
  components: {
    uploadPics,
    uploadPicss,
  },
  data() {
    return {
      approveDialog: {
        title: "修改快递单号",
        visible: false,
      },

      searchForm: {
        page: 1,
        size: 10,
        end:'',
        start:'',
        end:'',
        name:'',
        orderNo:'',
        isPay:null,
        isSend:null,
        isConfirm:null,
        applyDelete:null,
        isErpRefund:null,
      },
      updateParmas:{
        id:'',
        isSend:'',
        expressNo:'',
        express:'中通快递',
        words:'',
  
      },
      total: 0,
      tableData: [],
      pageSizes: [10, 20, 30, 40, 50],
      unitList: [
        { name: "全部", id: "0" },
        { name: "待发货", id: "1" },
        { name: "待收货", id: "2" },
        { name: "已完成", id: "3" },
        { name: "待处理", id: "4" },
      ],
      unitType:'全部',
      dayTime: "",
      currentTime: "",
      sevenDays:"",
      thirtyDays:"",
      radio1: '',
      logistics:'0',
      logisticsList: [
        { name: "顺丰快递", id: "顺丰快递" },
        { name: "中通快递", id: "中通快递" },
      ],
      jsonData: [],
      importData:[],
    };
  },
  created() {
    this.currentTime = parseTime(new Date(), "{y}-{m}-{d}");
    // console.log(this.sevenDays,this.thirtyDays);
    this.paramcshopmallIntegralPage();
    this.querycshopmallIntegralOrder()
  },
  methods: {
    querycshopmallIntegralOrder() {
      querycshopmallIntegralOrder({
        end:this.searchForm.end,
        start: this.searchForm.start,
        name:this.searchForm.name,
        orderNo:this.searchForm.orderNo,
        isPay:this.searchForm.isPay,
        isSend:this.searchForm.isSend,
        isConfirm:this.searchForm.isConfirm,
        applyDelete:this.searchForm.applyDelete,
        isErpRefund:this.searchForm.isErpRefund,
      }).then((res) => {
        this.importData=res
      });
    },
    down() {
      this.jsonData = [];
      this.$message.success("努力导出中");
      for (var i of this.importData) {
        this.jsonData.push({
          订单号: i.orderNo,
          下单用户: i.nickname,
          商品名: i.goodsName,
          规格: '默认',
          数量: i.num,
          货号: i.attr,
          总金额: i.totalPrice,
          实际付款: i.payPrice,
          下单时间: i.addDay,
          支付方式: i.typeName,
          付款状态: i.payName,
          付款时间: i.payDay,
          备注表单: i.remark,
        });
      }
    },
   
    tabHeader() {
      return "backgroundColor:rgba(88, 227, 232, 0.12);color:#000;font-weight:bold;font-size:15px;text-align: center;";
    },

    clearData() {
      this.parmas.activityName = "";
      this.parmas.activityNums = 0;
      this.parmas.banner = "";
      this.parmas.beginDay = "";
      this.parmas.type = "0";
      this.parmas.endDay = "";
      this.parmas.rules = "";
      this.parmas.wxCode = "";
      this.dayTime = "";
      this.bannerfileList = [];
      this.bannerdialogImageUrl = "";
      this.bannerdialogVisible = false;
      this.wxfileList = [];
      this.wxdialogImageUrl = "";
      this.wxdialogVisible = false;
    },
    //列表时间筛选
    selectTime(val) {
      console.log(val);
      if (val == null) {
        this.dayTime = "";
        this.searchForm.start = "";
        this.searchForm.end = "";
      } else {
        this.dayTime = [
          parseTime(val[0], "{y}-{m}-{d}"),
          parseTime(val[1], "{y}-{m}-{d}"),
        ];
        this.searchForm.start = this.dayTime[0];
        this.searchForm.end = this.dayTime[1];
      }
      this.radio1=''
      this.paramcshopmallIntegralPage();
    },



    // 列表
    paramcshopmallIntegralPage() {
      paramcshopmallIntegralPage(this.searchForm).then((res) => {
        this.tableData = res.records;
        this.total = res.total;
      });
    },


    changeUnit(val) {
      // 全部：5个为null
      // 未付款：isPay=0  其余为null
      // 待发货：isSend=0 其余为null
      // 待收货：isConfirm=0 其余为null
      // 已完成：isConfirm=1 其余为null
      // 待处理: applyDelete=1 其余为null
      console.log(val)
      if(val=='全部'){
        this.searchForm.isPay=null
        this.searchForm.isSend=null
        this.searchForm.isConfirm=null
        this.searchForm.applyDelete=null
        this.searchForm.isErpRefund=null
      }else if(val=='待发货'){
        this.searchForm.isPay=null
        this.searchForm.isSend=0
        this.searchForm.isConfirm=null
        this.searchForm.applyDelete=null
        this.searchForm.isErpRefund=null
      }else if(val=='待收货'){
        this.searchForm.isPay=null
        this.searchForm.isSend=null
        this.searchForm.isConfirm=0
        this.searchForm.applyDelete=null
        this.searchForm.isErpRefund=null
      }else if(val=='已完成'){
        this.searchForm.isPay=null
        this.searchForm.isSend=null
        this.searchForm.isConfirm=1
        this.searchForm.applyDelete=null
        this.searchForm.isErpRefund=null
      }else if(val=='待处理'){
        this.searchForm.isPay=null
        this.searchForm.isSend=null
        this.searchForm.isConfirm=null
        this.searchForm.applyDelete=1
        this.searchForm.isErpRefund=null
      }
      this.paramcshopmallIntegralPage();
    
    },

    changeLabel(val){
      var sevenDays = new Date(new Date().getTime() - 24 * 60 * 60 * 1000 * 7); 
      var thirtyDays = new Date(new Date().getTime() - 24 * 60 * 60 * 1000 * 30); 
      if(val=='近7天'){
        this.sevenDays = parseTime(sevenDays, "{y}-{m}-{d}");
        this.searchForm.start = this.sevenDays;
        this.searchForm.end = this.currentTime;
      }else{
        this.thirtyDays = parseTime(thirtyDays, "{y}-{m}-{d}");
        this.searchForm.start = this.thirtyDays;
        this.searchForm.end = this.currentTime;
      }
      this.dayTime = [
        this.searchForm.start,
        this.searchForm.end,
        ];
      this.paramcshopmallIntegralPage();
    },

    //取消按钮
    resetThemeForm() {
      // this.clearData()
      this.approveDialog.visible = false;
      this.paramcshopmallIntegralPage();
    },
    //查询

    searchClick(row) {
      this.detailRow = row;

    },

    handleRadioChange(value) {
      this.logistics=value
},
logisticsUnit(val){
 this.updateParmas.express=val
},
//发货
sendClick(row,isSend){
  this.updateParmas.id=row.id
  this.updateParmas.isSend=isSend
  this.approveDialog.visible = true;
},
    //修改快递单号
    editorClick(row) {
      // console.log(row)
        this.updateParmas.id=row.id
        this.updateParmas.isSend=row.isSend
        this.updateParmas.expressNo=row.expressNo
        this.updateParmas.express=row.express
        this.updateParmas.words=row.words
        this.approveDialog.visible = true;
    },


    //修改快递单号
    approvesubmitThemeForm(){
      if(this.updateParmas.express==''){
        this.$alert("请选择快递公司", "提示", {
          dangerouslyUseHTMLString: true,
        });
        return;
      }
      if(this.updateParmas.expressNo==''){
        this.$alert("请输入快递单号", "提示", {
          dangerouslyUseHTMLString: true,
        });
        return;
      }
      updatecshopmallIntegralOrder(this.updateParmas).then((res) => {
        this.approveDialog.visible = false;
        this.paramcshopmallIntegralPage()
      });
    },

    //通过，拒绝
    approveClick(row,isCancel){
      this.$confirm((isCancel==1?'同意':'拒绝') +'收货人'+row.name+"这条数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        checkshopmallIntegralOrder({
          id:row.id,
          isCancel:isCancel,
          applyDelete: 0,

          }).then((res) => {
            this.paramcshopmallIntegralPage();
          });
      });
  
    },

  },
};
</script>

<style scoped lang="scss">
/* .tree /deep/ .el-tree-node__content{
		padding-left: 40px
	} */
.red {
  color: red;
}
.turnBule {
  color: #333;
  background-color: #58e3e8;
  border-color: rgba(56, 185, 190, 0.12);
  margin-left: 15px;
}

.turnBule:hover,
.turnBule:focus {
  background: rgba(56, 185, 190, 0.5);
  border-color: rgba(56, 185, 190, 0.5);
  color: #fff;
}

.el-button--text.reviewed {
  color: #38b9be;
}

.reviewed {
  color: #38b9be;
}

.el-tree-node__content {
  z-index: 999;
  padding-left: 40px;
}

.directory-in {
  width: 32%;
  line-height: 60px;
  background: #4095e5;
}
.hide_box {
  ::v-deep.el-upload--picture-card {
    display: none;
  }
}
</style>
